"use client";

import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { useDispatch, useSelector } from "react-redux";
import {
  selectors as searchMaskSelectors,
  actions as searchMaskActions,
} from "@/store/searchMask";
import {
  selectors as routesSelectors,
  actions as routesActions,
} from "@/store/routes";
import TextField, { TextFieldProps } from "@/components/ui/text-field";
import { Port } from "@/global-types/ports";

import CountryFilter from "./country-filter";
import FilteredPorts from "./filtered-ports";
import PopularPorts from "./popular-ports";
import RecentSearches from "./recent-searches";

import styles from "./autocomplete.module.css";
import { Translations } from "@/global-types/translations";

const Dropdown = dynamic(() => import("@/components/dropdown"), { ssr: false });

interface Props extends TextFieldProps {
  portsData: Port[];
  routeType: "departure" | "arrival";
  routeIndex: number;
  initialPortLocationName: string;
  translations: Translations | undefined;
  lang: string;
}

const Desktop: React.FC<Props> = ({
  portsData,
  routeType,
  routeIndex,
  initialPortLocationName,
  lang,
  ...rest
}) => {
  const dispatch = useDispatch();
  const tripType = useSelector(routesSelectors.getTripType);
  const openReturnField = useSelector(searchMaskSelectors.getOpenReturnField);
  const [searchTerm, setSearchTerm] = useState<string>(initialPortLocationName);
  const [countryFilter, setCountryFilter] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setSearchTerm(value);
    setOpen(true);

    if (value.trim() === "") {
      if (tripType === "oneWay") {
        if (routeType === "departure") {
          dispatch(routesActions.resetRoutes());
          return;
        }
        if (routeType === "arrival") {
          dispatch(routesActions.changeRouteDate({ index: 0, value: "" }));
          dispatch(routesActions.changeRouteArrival({ index: 0, value: "" }));
          return;
        }
      }
      if (tripType === "roundTrip") {
        if (routeType === "departure") {
          dispatch(routesActions.resetRoutes());
          return;
        }
        if (routeType === "arrival") {
          dispatch(routesActions.changeRouteArrival({ index: 0, value: "" }));
          dispatch(routesActions.changeRouteDate({ index: 0, value: "" }));
          dispatch(routesActions.changeRouteDeparture({ index: 1, value: "" }));
          dispatch(routesActions.changeRouteDate({ index: 1, value: "" }));
          return;
        }
      }
      if (tripType === "multiTrip") {
        if (routeType === "departure") {
          dispatch(
            routesActions.changeRouteDeparture({
              index: routeIndex,
              value: "",
            }),
          );
          dispatch(
            routesActions.changeRouteArrival({ index: routeIndex, value: "" }),
          );
          dispatch(
            routesActions.changeRouteDate({ index: routeIndex, value: "" }),
          );
          return;
        }
        if (routeType === "arrival") {
          dispatch(
            routesActions.changeRouteArrival({ index: routeIndex, value: "" }),
          );
          dispatch(
            routesActions.changeRouteDate({ index: routeIndex, value: "" }),
          );
          return;
        }
      }
    }
  };

  const handleInputFocus = (): void => {
    inputRef.current?.select();
    setOpen(true);
  };

  const handleSuggestionClick = (value: string) => {
    setSearchTerm(value);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setCountryFilter("");
    if (openReturnField === `to-${routeIndex}`) {
      dispatch(searchMaskActions.changeOpenReturnField(null));
    }
  };

  useEffect(() => {
    setSearchTerm(initialPortLocationName);
  }, [initialPortLocationName]);

  useEffect(() => {
    const returnInput = document.getElementById(`to-${routeIndex}`);
    if (openReturnField === `to-${routeIndex}` && returnInput) {
      returnInput.focus();
    }
  }, [openReturnField, routeIndex]);

  return (
    <>
      <TextField
        inputSize="lg"
        ref={inputRef}
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onContextMenu={(e) => e.preventDefault()}
        autoComplete="off"
        {...rest}
      />

      <Dropdown
        open={open}
        anchorEl={inputRef.current}
        onClose={handleClose}
        className={clsx(styles.dropdown, { hidden: !open, flex: open })}
      >
        {searchTerm.trim() ? (
          <FilteredPorts
            searchTerm={searchTerm}
            portsData={portsData}
            onSuggestionClick={handleSuggestionClick}
            routeType={routeType}
            routeIndex={routeIndex}
            translations={rest.translations}
          />
        ) : (
          <>
            <CountryFilter
              portsData={portsData}
              countryFilter={countryFilter}
              setCountryFilter={setCountryFilter}
              translations={rest.translations}
              lang={lang}
            />
            <div
              className={clsx("grid flex-1 gap-4 overflow-hidden", {
                "grid-cols-1": routeType === "arrival",
                "grid-cols-2": routeType === "departure",
              })}
            >
              <PopularPorts
                portsData={portsData}
                onSuggestionClick={handleSuggestionClick}
                routeType={routeType}
                routeIndex={routeIndex}
                countryFilter={countryFilter}
                translations={rest.translations}
                lang={lang}
              />
              {routeType === "departure" && (
                <RecentSearches
                  portsData={portsData}
                  translations={rest.translations}
                  onClose={handleClose}
                  lang={lang}
                />
              )}
            </div>
          </>
        )}
      </Dropdown>
    </>
  );
};

export default Desktop;
